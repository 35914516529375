<template>
  <div>
    <Nav></Nav>
    <div class="cont cont-color">
      <div v-if="screenWidth > 750">
        <div class="lllllll">
          <el-table :data="tableData" style="border-right: 5px solid #ececec; margin-top: 20px">
            <template>
              <el-table-column prop="date" align="center">
                <template slot-scope="scope">
                  <router-link :to="{ path: '/article/' + scope.row.infoid }" style="width: 100%; box-sizing: border-box; cursor: pointer">
                    <!-- <div class="article-img-box">
                <span>[ {{scope.row.catename}} ]</span>
              </div> -->
                    <div class="article-text">
                      <p class="article-title">{{ scope.row.title }}</p>
                      <p class="info"></p>
                      <p>▶▶详情</p>
                    </div>
                    <div class="date-box">
                      <p class="date-day" style="height: 85px; padding-top: 30px">{{ scope.row.timeDay }}</p>
                      <p class="date-month">{{ scope.row.timeYear }}-{{ scope.row.timeMonth }}</p>
                    </div>
                  </router-link>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </div>
        <div class="block">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page" :page-size="limit" layout="total,prev, pager, next" :total="total"> </el-pagination>
        </div>
      </div>
      <div v-else style="margin-bottom: 30px">
        <ul class="news-container container-fuild">
          <li v-for="(item, index) in tableData" :key="index" class="wow fadeIn">
            <div class="cont">
              <router-link :to="{ path: '/article/' + item.infoid }" style="width: 100%; box-sizing: border-box; cursor: pointer">
                <!-- <p>{{ item.catename }}</p> -->
                <p>{{ item.title }}</p>
              </router-link>
            </div>
            <div class="time">
              <p>{{ item.timeMonth }}-{{ item.timeDay }}</p>
              <span>{{ item.timeYear }}</span>
            </div>
          </li>
        </ul>
        <div class="contaianer-fuild text-center more">
          <i class="glyphicon glyphicon-th"></i>
        </div>
        <el-button class="button-left" size="small" @click="previous" v-if="page != 1">上一页</el-button>
        <el-button class="button-right" size="small" @click="next" v-if="!(page * limit >= total)">下一页</el-button>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import articleApi from '@/api/article'
import moment from 'moment'
import { WOW } from 'wowjs'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Nav,
    Footer
  },
  data() {
    return {
      page: 1, // 当前页
      limit: 10, // 每页记录数
      total: 100, // 总记录数
      tableData: [],
      bannerHeight: 500,
      screenWidth: 0
    }
  },
  methods: {
    handleSizeChange(val) {
      this.limit = val
      this.getlist.call(this)
    },
    handleCurrentChange(val) {
      this.page = val
      this.getlist.call(this)
    },
    getlist() {
      this.listLoading = true
      let page = this.page
      let limit = this.limit
      var that = this
      articleApi
        .getArticleListBylist(page, limit)
        .then((res) => {
          that.tableData = res.data.data.tableData
          that.total = res.data.data.total
          that.listLoading = false
          that.listFor()
        })
        .catch(function (error) {})
    },
    dateFormat(timeline) {
      var time = timeline * 1000
      return moment(time).format('YYYY-MM-DD')
    },
    dateFormatYear(timeline) {
      var time = timeline * 1000
      return moment(time).format('YYYY')
    },
    dateFormatMonth(timeline) {
      var time = timeline * 1000
      return moment(time).format('MM')
    },
    dateFormatDay(timeline) {
      var time = timeline * 1000
      return moment(time).format('DD')
    },
    dateFormatday(timeline) {
      var time = timeline * 1000
      return moment(time).format('D')
    },
    listFor() {
      var list = this.tableData
      for (var i = 0; i < list.length; i++) {
        list[i].timeYear = this.dateFormatYear(list[i].timeline)
        list[i].timeMonth = this.dateFormatMonth(list[i].timeline)
        list[i].timeDay = this.dateFormatDay(list[i].timeline)
        list[i].timeday = this.dateFormatday(list[i].timeline)
        list[i].timeline = this.dateFormat(list[i].timeline)
      }
    },
    setSize1: function () {
      //图片
      this.bannerHeight = (500 / 1130) * this.$store.state.screenWidth - 50
      //document.getElementById('el-carousel').style.height = this.bannerHeight + 'px';
    },
    previous() {
      this.page = this.page - 1
      this.limit = 10
      this.getlist.call(this)
    },
    next() {
      this.page = this.page + 1
      this.limit = 10
      this.getlist.call(this)
    }
  },
  // 初始化数据
  mounted() {
    this.getlist.call(this)
    var wow = new WOW()
    wow.init()
    this.setSize1()
    const that = this
    that.screenWidth = document.body.clientWidth
  },
  created() {
    window.screenWidth = document.body.clientWidth
    this.screenWidth = window.screenWidth
  }
}
</script>

<style scope>
.el-table__body tr:hover > td {
  background-color: #30889e !important;
  color: #fff;
}
.el-table th.el-table__cell.is-leaf,
.el-table td.el-table__cell {
  border-bottom: 6px solid #ebeef5;
}
.el-table__body tr:hover p {
  color: #fff;
}
::v-deep .el-table__body tbody tr:nth-child(odd) {
  background-color: #ff0000;
}
::v-deep .el-table__body tbody tr:nth-child(even) {
  background-color: #ff0000;
}
a {
  color: #000;
}
.lllllll {
  margin: 20px auto;
  max-width: 80%;
}
.lllllll .el-table__header-wrapper,
.ssss .el-table__header-wrapper {
  display: none;
}
.lllllll a {
  color: #2c3e50;
}
.cont-color {
  padding-top: 1px;
  background-color: #ececec;
}
.ssss {
  width: 100%;
  margin: 0 auto;
}
.block {
  padding: 20px;
  background-color: #fff;
}
.ssss .date-box {
  width: 50%;
  color: #b1b1b1;
  text-align: center;
  float: right;
}
.date-box {
  width: 10%;
  color: #b1b1b1;
  text-align: center;
  float: right;
}
.date-day {
  font-size: 6.67em;
}
.date-month {
  font-size: 1.75em;
}
.info {
  color: #808081;
  margin-bottom: 2.67em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.article-img-box {
  box-sizing: border-box;
  margin-right: 2em;
  flex-shrink: 0;
}
.article-text {
  float: left;
  width: 90%;
  text-align: left;
  color: #333;
  padding-top: 1em;
  text-indent: 1em;
}
.ssss .article-text {
  float: left;
  width: 100%;
  text-align: left;
  color: #333;
  padding-top: 1em;
  text-indent: 1em;
}
.article-title {
  font-size: 1.83em;
  margin-bottom: 0.636em;
  font-weight: bold;
}
.news-container {
  overflow: hidden;
  margin-bottom: 15px;
}
.news-container > li {
  width: 55.6%;
  float: left;
  color: #333;
  text-align: right;
  border-bottom: 5px solid #ececec;
}
.news-container > li:hover {
  color: #1e73be;
  border: 1px solid #1e73be;
  cursor: pointer;
}
.news-container > li:nth-of-type(2n) {
  float: right;
  text-align: left;
}
.news-container > li > .cont {
  padding: 20px 0;
}
.news-container > li > .time > p {
  font-size: 30px;
  margin: 5px 0;
}
.news-container > li > .circle {
  width: 20%;
  height: 100%;
  float: left;
  position: relative;
}
.news-container > li > .circle > img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 20px;
  height: 20px;
}
.news-container > li > .circle > i {
  display: block;
  width: 1px;
  height: 100%;
  background: #707070;
}
.news-container > li:nth-of-type(2n) > .cont {
  float: right;
}
.news-container > li:nth-of-type(2n) > .time {
  float: right;
}
.news-container > li:nth-of-type(2n) > .circle {
  float: right;
}
.news-container > li:nth-of-type(1) > .circle > i {
  height: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
}
.news-container > li > .cont.a {
  color: black;
}
.more {
  font-size: 25px;
  color: #707070;
}
.more > i {
  cursor: pointer;
}
.el-pagination {
  text-align: center;
}
.el-button {
  text-align: center;
}
.button-left {
  text-align: center;
  float: left;
  margin-left: 20%;
}
.button-right {
  text-align: center;
  float: right;
  margin-right: 20%;
}
@media screen and (max-width: 767px) {
  .news-container > li {
    width: 100%;
    height: 80px;
  }
  .cont-color {
    padding-top: 0px;
    background-color: white;
    padding-bottom: 30px;
    border-top: 5px solid #ececec;
  }
  .news-container > li > .cont {
    width: 70%;
    text-align: left;
    float: right;
    margin-right: 0.5rem;
  }
  .news-container > li > .time {
    padding-top: 10px;
    width: 25%;
    text-align: left;
    float: right;
  }
  .news-container > li > .circle {
    display: none;
  }
}
</style>
